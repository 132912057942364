import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HourRow, Hour, Button } from './StyledComponents';
import dayjs from 'dayjs';
import { addSlotToAvailability, removeSlotFromAvailability, getSlotByDate } from '../../../../Services/slots';

interface HourComponentProps {
    datetime: dayjs.Dayjs;
    timezone: string;
}

function HourComponent({ datetime, timezone }: HourComponentProps) {
    const { push } = useHistory();
    const [isAvailable, setIsAvailable] = useState(true);

    async function handleAddClick() {
        await addSlotToAvailability(datetime);
        setIsAvailable(true);
    };

    async function handleDeleteClick() {
        await removeSlotFromAvailability(datetime);
        setIsAvailable(false);
    }

    useEffect(() => {
        getSlotByDate(datetime).then((slot) => {
            if (slot) {
                setIsAvailable(true);
            } else {
                setIsAvailable(false);
            }
        }).catch(e => {
            if (e.request.status === 403) {
                localStorage.removeItem('cwc-token');
                push('/dashboard/login');
            }
        });
    }, [datetime, push]);

    return (
        <HourRow key={datetime.format()}>
            <Hour>{dayjs.tz(datetime, timezone).format('h A')}</Hour>
            <Button onClick={isAvailable ? handleDeleteClick : handleAddClick}>{isAvailable ? "Delete" : "Make Available"}</Button>
        </HourRow>
    );
};

export {
    HourComponent
}
