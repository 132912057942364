let base = '';

if (process.env.NODE_ENV === 'development') {
    base = 'http://localhost:5000'
} else {
    base = 'https://cwc-mentoring.herokuapp.com';
}

export {
    base
}