import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;
`;

const Card = styled.div`
    width: 75%;
    border-radius: 2%;
    box-sizing: border-box;
    color: black;
    font-family: tahoma;
    letter-spacing: 1px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;
    @media (max-width: 1200px) {
        width: 90%;
    }

    @media (max-width: 600px) {
        width: 100%;
        margin-top: 0;
    }
`;

const HeaderRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
`;

const WelcomeHeader = styled.h1`
    margin: 0;
    font-family: tahoma;
    padding-top: 34px;
    padding-left: 34px;
    padding-right: 34px;
    color: white;
`;

const SubHeader = styled.h3`
    margin: 0;
    font-family: tahoma;
    padding-top: 34px;
    padding-left: 34px;
    padding-right: 34px;
    color: white;
`;

const InfoRow = styled.div`
    width: 90%;
    padding-top: 34px;
    padding-left: 34px;
    padding-right: 34px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ActionRow = styled.div`
    width: 100%;
    padding: 34px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const InfoSquare = styled.div`
    box-shadow: 1px 1px 15px white;
    padding: 14px;
    width: 41%;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1200px) {
        width: 90%;
        margin-bottom: 24px;
    }
`;

const Button = styled.button`
    border: none;
    background-color: #001d2e;
    color: white;
    text-decoration: none;
    padding: 24px;
    box-sizing: border-box;
    width: 50%;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    font-family: tahoma;
    font-size: 24px;
    transition: all .2s ease-in-out;
    border: 1px solid white;
    @media (max-width: 600px) {
        width: 90%;
    }
    :hover {
        box-shadow: 6px 8px 10px 0px rgba(225, 225, 225);
        font-size: 34px;
    }
`;

const SalesText = styled.span`
    font-family: tahoma;
    letter-spacing: 1.5px;
    font-size: 24px;
    color: white;
`;

const List = styled.ul`
    color: white;
    letter-spacing: 1.5px;
    font-family: tahoma;
    line-height: 28px;
    font-weight: 550;
`;

function HomePageContainer() {
    return (
        <Container>
            <Card>
                <HeaderRow>
                    <WelcomeHeader>Welcome to Coding With Chaim</WelcomeHeader>
                    <SubHeader>Level up your web development skills</SubHeader>
                </HeaderRow>
                <InfoRow>
                    <InfoSquare>
                        <SalesText> Get 1 on 1 Mentorship and level up your web development skills for only <b>$95</b> a session!</SalesText>
                    </InfoSquare>
                    <InfoSquare>
                        <SalesText>Get expert level mentorship on</SalesText>
                        <List>
                            <li>JavaScript</li>
                            <li>React</li>
                            <li>Node</li>
                            <li>Interview Prep</li>
                            <li>Career Advice</li>
                            <li>And More....</li>
                        </List>
                    </InfoSquare>
                </InfoRow>
                <ActionRow>
                    <Button to='/available-sessions' as={Link}>
                        Book a session
                    </Button>
                </ActionRow>
            </Card>
        </Container>
    )
};

export {
    HomePageContainer,
}
