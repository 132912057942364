import axios from 'axios';
import dayjs from 'dayjs';
import { base } from './baseUrl';

interface AddSessionBody {
    learnerEmail: string,
    sessionDescription: string,
    learnerName: string,
    availableSlotId: number,
    learnerTimezone: string
}

async function cancelSession({ sessionId, notify }: { sessionId: number, notify?: boolean }) {
    await axios({
        method: 'delete',
        url: `${base}/api/sessions/${sessionId}`,
        data: {
            notify
        }
    });
}

async function getMySchedule() {
    const today = dayjs().startOf('day').toDate();
    const endOfWeek = dayjs().endOf('week').toDate();
    const token = localStorage.getItem('cwc-token');
    const res = await axios({
        url: `${base}/api/sessions?today=${today}&endofweek=${endOfWeek}`,
        headers: {
            'cwc-token': token
        }
    });

    interface Session {
        dateTime: Date;
        id: number;
        isConfirmed: boolean;
        learnerEmail: string;
        learnerName: string;
        meetingUrl: string;
        sessionEnded: boolean;
    }
    const schedule: Array<Session> = res.data.schedule;

    const groupedByDay = schedule.reduce((acc: Array<{day: Date, sessions: Array<Session>}>, current) => {
        const alreadyExists = acc.find(session => dayjs(session.day).isSame(current.dateTime, 'day'));
        if (alreadyExists) {
            alreadyExists.sessions.push(current);
        } else {
            acc.push({
                day: current.dateTime,
                sessions: [current]
            })
        }

        return acc;
    }, []);

    return groupedByDay;
}

async function endSession(sessionId: number) {
    const token = localStorage.getItem('cwc-token');
    await axios({
        method: 'patch',
        url: `${base}/api/sessions/${sessionId}`,
        data: {
            action: 'end'
        },
        headers: {
            'cwc-token': token
        }
    });
}

async function getSessionDetails(sessionId: number) {
    try {
        const token = localStorage.getItem('cwc-token');
        const res = await axios({
            url: `${base}/api/sessions/${sessionId}`,
            headers: {
                'cwc-token': token,
            }
        });
        return {
            learnerEmail: res.data.learnerEmail,
            learnerName: res.data.learnerName,
            sessionDescription: res.data.sessionDescription,
            isConfirmed: res.data.isConfirmed,
        }
    } catch (e) {
        throw e;
    }
}

async function confirmSession(sessionId: number) {
    const token = localStorage.getItem('cwc-token');
    await axios({
        method: 'patch',
        data: {
            action: 'confirm'
        },
        headers: {
            'cwc-token': token
        },
        url: `${base}/api/sessions/${sessionId}`
    });
}

async function addSession(body: AddSessionBody) {
    const res = await axios({
        url: `${base}/api/sessions`,
        method: 'post',
        data: body
    });
    return res.data.id;
}

export {
    cancelSession,
    getMySchedule,
    endSession,
    getSessionDetails,
    confirmSession,
    addSession
}