import { useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Link, useHistory } from 'react-router-dom';
import { getMySchedule, endSession } from '../../../../Services/sessions';

dayjs.extend(utc);
dayjs.extend(timezone);
const userTimezone = dayjs.tz.guess();

interface Schedule {
    day: Date;
    sessions: Array<Session>;
}

interface Session {
    id: number;
    dateTime: Date;
    isConfirmed: boolean;
    learnerEmail: string;
    learnerName: string;
    sessionEnded: boolean;
    meetingUrl: string;
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    box-sizing: border-box;
    align-items: center;
`;

const DayHeader = styled.div`
    width: 50%;
    background-color: white;
    padding: 14px;
    box-sizing: border-box;
    margin-bottom: 24px;
    color: #01304c;
    font-weight: 600;
    letter-spacing: 1.5px;
    font-family: tahoma;
    border-radius: 12px;
    font-size: 18px;
`;

const Card = styled.div`
    background-color: transparent;
    border: 1px solid white;
    border-radius: 12px;
    width: 50%;
    height: 150px;
    margin-bottom: 24px;
    box-sizing: border-box;
    display: flex;
`;

const Col = styled.div<{ alignItems: string }>`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    align-items: ${props => props.alignItems};
`;

const Text = styled.span`
    color: white;
    font-family: tahoma;
    letter-spacing: 1.5px;
    margin-bottom: 12px;
    font-weight: 600;
`;

const A = styled.a`
    color: white;
    font-family: tahoma;
    letter-spacing: 1.5px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
`;

const Button = styled.button`
    background-color: white;
    color: #001d2e;
    border: none;
    border-radius: 5px;
    padding: 14px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 15px;
    font-family: tahoma;
    letter-spacing: 1.5px;
    font-weight: 600;
`;

const StyledLink = styled(Link)`
    color: white;
    font-family: tahoma;
    letter-spacing: 1.5px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
    margin-top: 12px;
`;

const CardContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

function MySchedule() {
    const [schedule, setSchedule] = useState<Array<Schedule>>([]);
    const [loading, setLoading] = useState(false);
    const { push } = useHistory();

    useEffect(() => {
        getMySchedule().then(schedule => {
            setSchedule(schedule);
        }).catch(e => {
            if (e.request.status === 403) {
                localStorage.removeItem('cwc-session');
                push('/dashboard/login');
            }
        })
    }, [push]);

    async function handleEndClick(sessionId: number) {
        setLoading(true);
        await endSession(sessionId);
        const newSchedule = await getMySchedule();
        setSchedule(newSchedule);
        setLoading(false);
    }

    function renderSession(session: Session) {
        return (
            <Card key={dayjs(session.dateTime).format()}>
                <Col alignItems="flex-start">
                    <Text>{dayjs.tz(new Date(session.dateTime), userTimezone).format('h A')}</Text>
                    <Text>{session.learnerName}</Text>
                    <Text>{session.learnerEmail}</Text>
                    <Text>Session: {session.isConfirmed ? "Confirmed" : "Not Confirmed"}</Text>
                </Col>
                <Col alignItems="flex-end">
                    <A href={session.meetingUrl} target="_blank">Go to meeting</A>
                    {session.sessionEnded ? <Text>Session ended</Text> :
                        loading ? <Text>Loading...</Text> : <Button onClick={() => handleEndClick(session.id)}>End session</Button>}
                    <StyledLink to={`/dashboard/session/${session.id}`}>View Session</StyledLink>
                </Col>
            </Card>
        )
    }

    function renderSchedule(schedule: Schedule) {
        return (
            <CardContainer style={{ width: '100%' }} key={dayjs(schedule.day).format()}>
                <DayHeader>
                    {dayjs(schedule.day).format('dddd MMM/D')}
                </DayHeader>
                {schedule.sessions.map(renderSession)}
            </CardContainer>
        );
    };

    return (
        <Container>
            {schedule.map(renderSchedule)}
        </Container>
    );
};

export {
    MySchedule
}
