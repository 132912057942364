import { ReactNode } from 'react';
import styled from 'styled-components';

interface LayoutProps {
    children: ReactNode;
}

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

function Layout({ children }: LayoutProps) {
    return (
        <Container>
            {children}
        </Container>
    );
};

export {
    Layout
}
