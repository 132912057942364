import { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
    Card,
    CardHeader,
    Container,
} from './StyledComponents';
import { HourComponent } from './Hour';

dayjs.extend(utc);
dayjs.extend(timezone);

const userTimezone = dayjs.tz.guess();

interface Day {
    dayOfWeek: dayjs.Dayjs;
    hours: Array<dayjs.Dayjs>;
}

function getInitialDays() {
    const days: Array<Day> = [];
    for (let i = 0; i <= 6; i++) {
        let today = dayjs().day(i).startOf('day');
        const day: Day = {
            dayOfWeek: today,
            hours: []
        };

        while (today.isSame(dayjs().day(i), 'day')) {
            today = today.add(1, 'hour');
            day.hours.push(today);
        }

        days.push(day);
    }

    return days;
};

function CreateSchedule() {
    const [days] = useState<Array<Day>>(getInitialDays());

    function renderDays(day: Day) {
        return (
            <Card key={day.dayOfWeek.format('dddd')}>
                <CardHeader>
                    {day.dayOfWeek.format('M/DD dddd')}
                </CardHeader>
                {day.hours.map(renderHours)}
            </Card>
        );
    }

    function renderHours(datetime: dayjs.Dayjs) {
        return (
            <HourComponent timezone={userTimezone} key={datetime.format()} datetime={datetime} />
        )
    }

    return (
        <Container>
            {days.map(renderDays)}
        </Container>
    );
};

export {
    CreateSchedule
}
