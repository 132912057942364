import axios from 'axios';
import { base } from './baseUrl';

async function login(password: string) {
    const { data } = await axios({
        method: 'post',
        url: `${base}/api/auth`,
        data: {
            password
        }
    });
    return data.token;
}

export {
    login,
}