import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Dashboard } from './Pages/Dashboard/Container';
import { Login } from './Pages/Dashboard/Pages/Login/Container';
import { AvailableSessions } from './Pages/AvailableSessions/Container';
import { HomePageContainer } from './Pages/Home/Container';
import { ReserveSession } from './Pages/ReserveSession/Container';
import { Layout } from './Components/Layout';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={'/dashboard/login'} component={Login} />
        <Route path="/dashboard" component={Dashboard} />
        <Layout>
          <Route path='/' exact component={HomePageContainer} />
          <Route path="/available-sessions" component={AvailableSessions} />
          <Route path='/reserve-session/:id' component={ReserveSession} />
        </Layout>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
