import styled from 'styled-components';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { login } from '../../../../Services/auth';

const Page = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const Container = styled.div`
    margin-top: 10%;
    width: 25%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
`;

const Input = styled.input`
    background-color:transparent;
    color: white;
    font-size: 18px;
    font-family: tahoma;
    letter-spacing: 1.5px;
    box-sizing: border-box;
    border: none;
    width: 100%;
    padding: 12px;
    border: 1px solid white;
    transition: border-color .3s ease-in-out;
    border-radius: 5px;
    margin-bottom: 34px;
    ::placeholder {
        color: rgba(225, 225, 225, .8);
    }
    :focus {
        outline: none;
        border: 1px solid #009bf3;
    }
`;


const Button = styled.button`
    @media(max-width: 800px) {
        width: 90%;
    }
    width: 55%;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 24px;
    box-sizing: border-box;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    transition: all .2s ease-in-out;
    height: 64px;
    :hover { 
        background-color: ${props => props.disabled ? 'transparent' : 'white'};
        color: ${props => props.disabled ? "white" : "#001d2e"};
    }
`;

function Login() {
    const [password, setPassword] = useState('');
    const { push } = useHistory();

    async function handleLoginClick() {
        const token = await login(password);
        if (token) {
            localStorage.setItem('cwc-token', token);
            push('/dashboard');
        }
    }

    return (
        <Page>
            <Container>
                <Input
                    type="password"
                    value={password} onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <Button onClick={handleLoginClick}>Login</Button>
            </Container>
        </Page>
    );
};

export {
    Login
}