import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from '../CreateSchedule/StyledComponents';
import { getSessionDetails, confirmSession, cancelSession } from '../../../../Services/sessions';

const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 24px;
    padding-top: 44px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
`;

const Text = styled.span`
    color: white;
    font-family: tahoma;
    letter-spacing: 1.5px;
    font-weight: 550;
    margin-bottom: 24px;
    box-sizing: border-box;
`;

const ButtonWrapper = styled.div`
    margin-bottom: 14px;
`;

function SessionInfo() {
    const [loading, setLoading] = useState(false);
    const { push } = useHistory();
    const [sessionDetails, setSessionDetails] = useState({
        learnerName: '',
        learnerEmail: '',
        sessionDescription: '',
        isConfirmed: true,
    });
    const { sessionId } = useParams<{ sessionId: string }>();
    const [toastMessage, setToastMessage] = useState('');

    useEffect(() => {
        getSessionDetails(parseInt(sessionId, 10)).then(details => setSessionDetails(details)).catch(e => {
            if (e.request.status === 403) {
                localStorage.removeItem('cwc-token');
                push('/dashboard/login');
            }
        })
    }, [sessionId, push]);

    async function handleConfirmClick() {
        try {
            setLoading(true);
            await confirmSession(parseInt(sessionId, 10));
            setToastMessage("session confirmed");
            setTimeout(() => {
                setToastMessage('');
            }, 2000);
        } catch (e) {
            setToastMessage("some went wrong");
            setTimeout(() => {
                setToastMessage('');
            }, 2000);
        } finally {
            const newDetails = await getSessionDetails(parseInt(sessionId, 10));
            setSessionDetails(newDetails);
            setLoading(false);
        }
    }

    async function handleDeclineClick(notify: boolean) {
        try {
            setLoading(true);
            await cancelSession({sessionId: parseInt(sessionId, 10), notify});
            setToastMessage("session declined");
        } catch (e) {
            setToastMessage("some went wrong");
            setTimeout(() => {
                setToastMessage('');
            }, 2000);
        } finally {
            setLoading(false);
            push('/dashboard')

        }
    }

    return (
        <Container>
            <Text>{sessionDetails.learnerName}</Text>
            <Text>{sessionDetails.learnerEmail}</Text>
            <Text>{sessionDetails.sessionDescription}</Text>
            {!sessionDetails.isConfirmed && (
                <>
                    <ButtonWrapper>
                        <Button onClick={handleConfirmClick}>Accept</Button>
                    </ButtonWrapper><ButtonWrapper>
                        <Button onClick={() => handleDeclineClick(false)}>Decline</Button>
                    </ButtonWrapper><ButtonWrapper>
                        <Button onClick={() => handleDeclineClick(true)}>Decline with email</Button>
                    </ButtonWrapper>
                </>
            )}
            {loading && <Text>Loading...</Text>}
            <Text>{toastMessage}</Text>
        </Container>
    );
};

export {
    SessionInfo
}
