import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import styled, { keyframes, css } from 'styled-components';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import TextareaAutosize from 'react-autosize-textarea';
import produce from 'immer';
import { getSlotById } from '../../Services/slots';
import { addSession, cancelSession } from '../../Services/sessions';

const emailReg = /^\S+@\S+\.\S+$/;

dayjs.extend(utc);
dayjs.extend(timezone);

const loading = keyframes`
 0%, 5%, 95%, 100% {
        left: 0%;
        transform: translate(0, 0);
        background-color: transparent;
    }
    45%, 55% {
        left: 100%;
        transform: translate(-100%, 0);
        background-color: white;
    }
 `;

const animationCss = css`
    animation-name: ${loading};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
`;

const userTimezone = dayjs.tz.guess();

const Page = styled.div`
    display: flex;
    width: 100;
    padding: 24px;
    justify-content: center;
    box-sizing: border-box;
`;

const Container = styled.div`
    display: flex;
    width: 45%;
    align-items: center;
    @media (max-width: 1000px) {
        width: 95%;
    }
    flex-direction: column;
`;

const Header = styled.h2`
    color: white;
    font-family: tahoma;
    letter-spacing: 1.5px;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
`;

const Form = styled.form`
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
`;

const Input = styled.input`
    background-color:transparent;
    color: white;
    font-size: 18px;
    font-family: tahoma;
    letter-spacing: 1.5px;
    box-sizing: border-box;
    border: none;
    width: 100%;
    padding: 12px;
    border: 1px solid white;
    transition: border-color .3s ease-in-out;
    border-radius: 5px;
    margin-bottom: 34px;
    ::placeholder {
        color: rgba(225, 225, 225, .8);
    }
    :focus {
        outline: none;
        border: 1px solid #009bf3;
    }
`;

const Field = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 44px;
    position: relative;
`;

const Text = styled(TextareaAutosize)`
    width: 100%;
    height: 150px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    padding: 12px;
    font-size: 18px;
    font-family: tahoma;
    letter-spacing: 1.5px;
    box-sizing: border-box;
    transition: border-color .3s ease-in-out;
    margin-bottom: 24px;
    resize: none;
    ::placeholder {
        color: rgba(225, 225, 225, .8);
    }
    :focus {
        outline: none;
        border: 1px solid #009bf3;
    }
`;

const Button = styled.button`
    @media(max-width: 800px) {
        width: 90%;
    }
    width: 55%;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 24px;
    box-sizing: border-box;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    transition: all .2s ease-in-out;
    height: 64px;
    :hover { 
        background-color: ${props => props.disabled ? 'transparent' : 'white'};
        color: ${props => props.disabled ? "white" : "#001d2e"};
    }
`;

const Loader = styled.div`
    @media(max-width: 1000px) {
        width: 95%;
    }
    width: 50%;
    height: 64px;
    position: relative;
    box-sizing: border-box;
`;

const Pendulum = styled.div`
    position: absolute;
    height: 100%;
    width: 45%;
    background-color: transparent;
    border-radius: 5px;
    right: 100%;
    border: 1px solid white;
    ${animationCss}
    color: #001d2e;
    font-family: tahoma;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
`;

const Error = styled.span`
    color: #c21e56;
    font-size: 16px;
    font-family: tahoma;
    font-weight: 600;
    letter-spacing: 1.5px;
    top: 22%;
    right: 2%;
    position: absolute;
    @media(max-width: 800px) {
        top: -25%;
    }
`;

const TextError = styled(Error)`
    top: 5%;
    @media(max-width: 800px) {
        top: -12%;
    }
`;

const P = styled.p`
    color: white;
    font-size: 22px;
    font-family: tahoma;
    letter-spacing: 1.5px;
    margin-top: 15%;
    box-sizing: border-box;
`;

interface State {
    [key: string]: string
    name: string,
    email: string,
    sessionDescription: string,
}

interface Errors {
    [key: string]: boolean;
    name: boolean;
    email: boolean;
    sessionDescription: boolean;
    invalidEmail: boolean;
}

function ReserveSession() {
    const [loading, setLoading] = useState(false);
    const [alreadyBooked, setAlreadyBooked] = useState(false);
    const [sessionCancelled, setSessionCancelled] = useState(false);
    const [datetime, setDatetime] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState('');

    const [values, setValues] = useState<State>({
        name: '',
        email: '',
        sessionDescription: '',
    });

    const [errors, setErrors] = useState<Errors>({
        name: false,
        email: false,
        sessionDescription: false,
        invalidEmail: false,
    });

    const { id } = useParams<{ id: string }>();

    async function checkIfBooked() {
        const slot = await getSlotById(parseInt(id));
        setDatetime(new Date(slot.dateTime));
        const sessionIdFromStorage = localStorage.getItem('reservedSessionId');
        if (sessionIdFromStorage && slot.sessionId) {
            if (parseInt(sessionIdFromStorage, 10) === slot.sessionId) {
                setAlreadyBooked(true);
            }
        }
    }

    const callBackedCheckIfBooked = useCallback(checkIfBooked, [id]);

    useEffect(() => {
        callBackedCheckIfBooked();
    }, [callBackedCheckIfBooked]);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const newErrors = produce(errors, draft => {
            if (e.target.name === 'email') {
                draft.invalidEmail = false;
            }
            draft[e.target.name] = false;
        });

        setErrors(newErrors);

        const newValues = produce(values, draft => {
            draft[e.target.name] = e.target.value;
        });
        setValues(newValues);
    }

    function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
        const newErrors = produce(errors, draft => {
            if (e.target.name === 'email' && e.target.value) {
                if (!emailReg.test(e.target.value)) {
                    draft.invalidEmail = true;
                }
            }

            if (!e.target.value) {
                draft[e.target.name] = true;
            }
        });
        setErrors(newErrors);
    }

    function hasErrors() {
        return Object.keys(errors).some(key => errors[key]);
    }

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const newErrors = produce(errors, draft => {
            Object.keys(values).forEach(key => {
                if (!values[key]) {
                    draft[key] = true;
                }
            })
        });

        setErrors(newErrors);

        if (!Object.keys(newErrors).some(key => newErrors[key])) {
            setLoading(true);
            try {
                const newSessionId = await addSession({
                    learnerEmail: values.email,
                    sessionDescription: values.sessionDescription,
                    learnerName: values.name,
                    availableSlotId: parseInt(id, 10),
                    learnerTimezone: userTimezone
                });
                localStorage.setItem('reservedSessionId', newSessionId);
                await checkIfBooked();
                setLoading(false);
            } catch(e) {
                setLoading(false);
                setErrorMessage("Something went wrong, please try again");
            }
        }
    }

    async function handleCancelClick() {
        const sessionIdFromStorage = localStorage.getItem('reservedSessionId');
        if (sessionIdFromStorage) {
            setLoading(true);
            await cancelSession({ sessionId: parseInt(sessionIdFromStorage, 10) });
            setLoading(false);
            setSessionCancelled(true);
        }
    }

    if (sessionCancelled) {
        return (
            <Page>
                <Container>
                    <Header>Session canceled successfully</Header>
                </Container>
            </Page>
        );
    }

    if (alreadyBooked) {
        return (
            <Page>
                <Container>
                    <P>
                        You have reserved a session. Your request will be reviewed,
                        once the request is accepted you will receive an email notifying you
                        that the session is confirmed. Until then, this time slot will be held for you.
                    </P>
                    {!loading ? <Button onClick={handleCancelClick}>Cancel session</Button> : <Loader>
                        <Pendulum>
                            Working
                        </Pendulum>
                    </Loader>}
                </Container>
            </Page>
        );
    }

    return (
        <Page>
            <Container>
                <Header>Reserve Session For {dayjs.tz(datetime, userTimezone).format('MMM D @h A')}</Header>
                <Header>Session Duration: 1 hour</Header>
                <Form onSubmit={handleSubmit}>
                    <Field>
                        {errors.name && <Error>Your name is required</Error>}
                        <Input
                            onBlur={handleBlur}
                            name='name'
                            value={values.name}
                            placeholder="Your name"
                            onChange={handleChange}
                        />
                    </Field>
                    <Field>
                        {errors.email && <Error>Your email is required</Error>}
                        {errors.invalidEmail && <Error>Please enter a valid email</Error>}
                        <Input
                            onBlur={handleBlur}
                            name='email'
                            value={values.email}
                            placeholder="Your email"
                            onChange={handleChange}
                        />
                    </Field>
                    <Field>
                        {errors.sessionDescription && <TextError>This field is required</TextError>}
                        <Text
                            onBlur={e => {
                                const newErrors = produce(errors, draft => {
                                    if (!e.currentTarget.value) {
                                        draft[e.currentTarget.name] = true;
                                    }
                                });
                                setErrors(newErrors);
                            }}
                            name='sessionDescription'
                            value={values.sessionDescription}
                            placeholder="How can I help?"
                            onChange={(e) => {
                                const newErrors = produce(errors, draft => {
                                    draft[e.currentTarget.name] = false;
                                });

                                setErrors(newErrors);
                                const newValues = produce(values, draft => {
                                    draft[e.currentTarget.name] = e.currentTarget.value;
                                });
                                setValues(newValues);
                            }}
                        />
                    </Field>
                    {!loading ? <Button disabled={hasErrors()}>Submit</Button> : <Loader>
                        <Pendulum>
                            Submitting
                        </Pendulum>
                    </Loader>}
                    <Header>{errorMessage}</Header>
                </Form>
            </Container>
        </Page>
    );
};

export {
    ReserveSession
}