import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Link } from 'react-router-dom';
import { cancelSession } from '../../Services/sessions';
import { getAllAvailableSessions } from '../../Services/slots';

dayjs.extend(utc);
dayjs.extend(timezone);

const userTimezone = dayjs.tz.guess();

const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 24px;
    padding-top: 44px;
    box-sizing: border-box;
`;

const DayCard = styled.div`
    width: 60%;
    display: flex;
    @media(max-width: 1000px) {
        width: 95%;
    }
    flex-direction: column;
`;

const DayHeaderRow = styled.div`
    background-color: transparent;
    padding: 24px;
    border-radius: 14px;
    border: 1px solid white;
`;

const DayHeader = styled.span`
    color: white;
    font-size: 24px;
    font-weight: bold;
    font-family: tahoma;
    letter-spacing: 1.5px;
`;

const HourRow = styled.div`
    width: 100%;
    padding: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NoSessionsRow = styled(HourRow)`
    justify-content: center;
    width: 100%;
`;

const Text = styled.span`
    font-family: tahoma;
    font-size: 18px;
    color: white;
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Button = styled.button`
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 4px;
    font-size: 18px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    text-decoration: none;
    :hover {
        background-color: white;
        color: #001d2e;
    }
`;

interface Day {
    dayOfWeek: dayjs.Dayjs;
    hours: Array<{
        id: number;
        datetime: Date;
        availableSlotId: number;
        sessionId: number;
    }>
}

function AvailableSessions() {
    const [slots, setSlots] = useState<Array<Day>>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllAvailableSessions().then(slots => {
            setSlots(slots);
        })
    }, []);

    async function handleCancelClick(sessionId: number) {
        setLoading(true);
        await cancelSession({sessionId});
        const slots = await getAllAvailableSessions();
        setSlots(slots);
        setLoading(false);
    }

    function renderHour({ id, datetime, availableSlotId, sessionId }: { id: number, datetime: Date, availableSlotId: number, sessionId: number }) {
        const sessionIdFromStorage = localStorage.getItem('reservedSessionId');
        const reservedByThisUser = sessionIdFromStorage && parseInt(sessionIdFromStorage, 10) === sessionId;

        let buttonToRender;
        if (reservedByThisUser) {
            buttonToRender = (
                <Button onClick={() => handleCancelClick(sessionId)}>{loading ? "Loading..." : "Cancel session"}</Button>
            );
        } else if (availableSlotId) {
            buttonToRender = (
                <Text>Reserved</Text>
            )
        } else {
            buttonToRender = (
                <Button as={Link} to={`/reserve-session/${id}`}>Reserve time slot</Button>
            );
        }

        return (
            <HourRow key={id}>
                <Text>{dayjs.tz(new Date(datetime), userTimezone).format('h A')}</Text>
                {buttonToRender}
            </HourRow>
        );
    }

    function renderDays(day: Day) {
        return (
            <DayCard key={dayjs(day.dayOfWeek).format()}>
                <DayHeaderRow>
                {/* {dayjs.tz(new Date(datetime), userTimezone).format('h A')} */}
                    <DayHeader>{dayjs.tz(day.dayOfWeek, userTimezone).format('M/D dddd')}</DayHeader>
                </DayHeaderRow>
                {day.hours.length ?
                    <Row>
                        {day.hours.map(renderHour)}
                    </Row>
                    : <NoSessionsRow>
                        <Text>No sessions available</Text>
                    </NoSessionsRow>
                }
            </DayCard>
        )
    }

    return (
        <Container>
            {slots.map(renderDays)}
        </Container>
    );
};

export {
    AvailableSessions,
}