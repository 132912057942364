import { Route, Switch, NavLink, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { CreateSchedule } from './Pages/CreateSchedule/CreateSchedule';
import { SessionInfo} from './Pages/Session/Container';
import { MySchedule } from './Pages/MySchedule/Container';

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
`;

const Sidebar = styled.div`
    height: 100%;
    width: 12%;
    display: flex;
    align-items: center;
    padding-top: 24px;
    box-sizing: border-box;
    flex-direction: column;
    border-right: 1px solid white;
`;

const Content = styled.div`
    height: 100%;
    flex: 1;
    padding: 24px;
    overflow-y: auto;
    box-sizing: border-box;
`;

const StyledLink = styled(NavLink)`
    color: white;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: 1px;
    margin-bottom: 12px;
`;

const activeLinkStyle = {
    textDecoration: 'underline',
    textDecorationColor: 'white',
    textUnderlineOffset: 4
};

function Dashboard() {
    const token = localStorage.getItem('cwc-token');
    if (!token) {
        return (
            <Redirect to="/dashboard/login" />
        )
    }

    return (
        <Container>
            <Sidebar>
                <StyledLink exact activeStyle={activeLinkStyle} to={`/dashboard`}>View Schedule</StyledLink>
                <StyledLink activeStyle={activeLinkStyle} to={`/dashboard/create-schedule`}>Create Schedule</StyledLink>
            </Sidebar>
            <Content>
                <Switch>
                    <Route path={'/dashboard/create-schedule'} component={CreateSchedule} />
                    <Route path={'/dashboard/session/:sessionId'} component={SessionInfo} />
                    <Route path={'/dashboard'} component={MySchedule} />
                </Switch>
            </Content>
        </Container>
    );
};

export {
    Dashboard
}