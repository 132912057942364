import axios from 'axios';
import dayjs from 'dayjs';
import { base } from './baseUrl';

interface Day {
    dayOfWeek: dayjs.Dayjs;
    hours: Array<{
        id: number;
        datetime: Date;
        availableSlotId: number;
        sessionId: number;
    }>
}

async function getAllAvailableSessions(): Promise<Day[]> {
    const today = dayjs();
    const res = await axios({
        url: `${base}/api/slots?datefrom=${today.startOf('day')}&dateto=${today.endOf('week')}`
    });
    const groupedByDay = [];
    for (let i = 0; i <= 6; i++) {
        let today = dayjs().day(i).startOf('day');
        const day: { dayOfWeek: dayjs.Dayjs, hours: Array<{ id: number, datetime: Date, availableSlotId: number, sessionId: number }> } = {
            dayOfWeek: today,
            hours: []
        };
        groupedByDay.push(day);
    }

    const slots: Array<{id: number, dateTime: Date, availableSlotId: number, sessionId: number}> = res.data.slots;
    const response = slots.reduce((acc, current) => {
        const currentDay = acc.find((day) => day.dayOfWeek.isSame(current.dateTime, 'day'));
        if (currentDay) {
            currentDay.hours.push({
                id: current.id,
                datetime: current.dateTime,
                availableSlotId: current.availableSlotId,
                sessionId: current.sessionId
            });
        }

        return acc;
    }, groupedByDay);

    response.forEach(day => {
        day.hours.sort((a, b) => {
            if (dayjs(a.datetime).isBefore(b.datetime, 'hour')) {
                return -1;
            }

            return 1;
        })
    })

    return response;
}

async function addSlotToAvailability(datetime: dayjs.Dayjs) {
    const token = localStorage.getItem('cwc-token');
    return await axios({
        method: 'post',
        url: `${base}/api/slots`,
        data: {
            datetime
        },
        headers: {
            'cwc-token': token
        }
    });
}

async function removeSlotFromAvailability(datetime: dayjs.Dayjs) {
    const token = localStorage.getItem('cwc-token');
    return await axios({
        method: "delete",
        url: `${base}/api/slots`,
        data: {
            datetime
        },
        headers: {
            'cwc-token': token
        }
    });
}

async function getSlotByDate(datetime: dayjs.Dayjs) {
    const token = localStorage.getItem('cwc-token');
    const res = await axios({
        url: `${base}/api/slots/${datetime}`,
        headers: {
            'cwc-token': token
        }
    });
    return res.data.slot;

}

async function getSlotById(id: number) {
    const res = await axios({
        url: `${base}/api/sessions/slot/${id}`
    });
    return res.data;
}

export {
    getAllAvailableSessions,
    addSlotToAvailability,
    removeSlotFromAvailability,
    getSlotByDate,
    getSlotById
}